import React from "react";
import SettingsMenu from "../../../components/SettingsMenu";
import SettingsMenuItem from "../../../components/SettingsMenuItem";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import ErrorBoundary from "../../../components/ErrorBoundary";
import MonthlyDashboard from "./MonthlyDashboard";

export default function ReportList({children, userId}) {
    let {path, url} = useRouteMatch();
    return <div>
        <h2 className={'text-xl hide-print'}>Reports</h2>
        <SettingsMenu>
            <SettingsMenuItem to={`${url}/monthly`}>Monthly</SettingsMenuItem>
            {/* <SettingsMenuItem to={`${url}/bank`}>1099 Interest</SettingsMenuItem> */}
        </SettingsMenu>
        <Switch>
            <Route exact path={`${path}/monthly`}>
                <ErrorBoundary key ={"monthly"}>
                    <MonthlyDashboard/>
                </ErrorBoundary>
            </Route>
        </Switch>

    </div>;
}

import React from "react";
import SettingsMenu from "../../../components/SettingsMenu";
import SettingsMenuItem from "../../../components/SettingsMenuItem";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import ErrorBoundary from "../../../components/ErrorBoundary";
import QuarterlyDashboard from "./QuarterlyDashboard";
import PrimaryButton from "../../../components/PrimaryButton";
import {FormattedMessage} from 'react-intl';
import MonthlyDashboard from "./MonthlyDashboard";

export default function ReportList({children, userId}) {
    let {path, url} = useRouteMatch();
    const print = () => {
        window.print();
    }
    return <div>
        <div className="d-inline-block">
            <span className="float-left">
                <h2 className={'text-xl hide-print'}><FormattedMessage id={'report.title'}/></h2>
            </span>
            <span className="float-right">
                <PrimaryButton onClick={() => print()}><FormattedMessage id={'print'}/></PrimaryButton>
            </span>
        </div>
        <SettingsMenu>
            <SettingsMenuItem to={`${url}/monthly`}><FormattedMessage id={'report.monthly'}/></SettingsMenuItem>
        </SettingsMenu>
        <Switch>
            <Route exact path={`${path}/monthly`}>
                <ErrorBoundary key ={"monthly"}>
                    <MonthlyDashboard/>
                </ErrorBoundary>
            </Route>
        </Switch>

    </div>;
}

import { FieldArray, Form, Formik } from 'formik';
import _ from "lodash";
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import FieldGroup from "../components/FieldGroup";
import FormErrors from "../components/FormErrors";
import LabeledField from "../components/LabeledField";
import Loading from "../components/Loading";
import MaskedLabeledField from "../components/MaskedLabeledField";
import PrimaryButton from "../components/PrimaryButton";
import { phoneNumberMask } from "../formikUtils";
import { apiDelete, apiGet } from "../utils";
import React from 'react';


const CompanyContactForm = ({doSubmit, errors, submitting, company, currentEmployee, rootStore}) => {
    const [enabled, setEnabled] = useState({});
    const [contacts, setContacts] = useState(company.contacts || []);
    const [userName, setUserName] = useState('');
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [showEmployeeToEmployer, setShowEmployeeToEmployer] = useState(false);
    const [uri, setUri] = useState('');
    let { url } = useRouteMatch();
    const [userId, setUserId] = useState('');
    const history = useHistory();

    const {register} = useForm();
    useEffect(() => {
        loadContacts();
    }, [company]);

    useEffect(() => {

        _.forEach(contacts, (contact) => {
            checkUsers(contact.email, contact.userName);
        });

    }, [contacts]);

    // const convert = async () => {
    //     const response = await apiPost(`/api/v1/accountHolder/convert/${userId}`, {}, rootStore.authStore, 'Contact ')
    //     let newUri = uri + response.id;

    //     history.push(newUri);
    // }

    const checkUsers = async (email, userName) => {
        const response = await apiGet(`/api/v1/contact/accountHolder/${email}`, rootStore.authStore);
        const resp = await response.json();
        if (resp.id && resp.id !== ''){
            setShowEmployeeToEmployer(true);
            setEmail(email);
            setContactName(userName);
            setUri(url.substring(0, url.indexOf('/settings/')) + `/employees/`);
            setUserId(resp.id);
        }
    }

    const loadContacts = async () => {
        const response = await apiGet(`/api/v1/contact/company/${company.id}`, rootStore.authStore);//company
        const resp = await response.json();
        setContacts(resp);
        if (!resp || resp.length === 0){
            setEnabled({[0] : true, ...enabled});
        }
        
    }

    const verifyContactUserName = async (user) => {
        if (user) {
            const response = await apiGet(`/api/v1/contact/check/username/${user}`, rootStore.authStore);
            const resp = await response.json();
            if(resp.error){
                rootStore.setErrorMessage(resp.error);
            }
        }
    }

    const submit = async (values) => {
        await _.forEach(values.contacts, (contact) => {
            contact.companyId = company.id;
        })

        doSubmit(values);

    }
    const onRemove = async (id) => {
        await apiDelete(`/api/v1/contact/delete/${id}`, rootStore.authStore, 'Contact');

        setContacts(contacts.filter(contact =>contact.id!==id));
    };

    return <>
        {/* {showEmployeeToEmployer ? 
        <p className={'py-4 text-red-400'}>
            We noticed that your contact with User Name <strong>{contactName}</strong> and Email <strong>{email}</strong> is registered with your main <i>Employer</i> account. 
            Now that you have an admin account to login with, would you like to use that account as an <i>Employee</i>?
            <Link to={``} onClick={() => convert()}
                className={"bg-primary mt-5 px-6 py-3 text-center text-white uppercase pr-4 mr-2 cursor-pointer float-right"}>Convert</Link>
        </p>
        : ''} */}
        <Formik
        enableReinitialize={true}
        initialValues={{
            contacts: contacts.length > 0 ? contacts : [{
                id: '',
                name: '',
                title: '',
                phone: '',
                email: '',
                companyId: '',
                userName: ''
            }],


        }}
        onSubmit={(values) => {
            submit(values)
        }}
         validationSchema={Yup.object().shape({
                contacts: Yup.array() .of ( Yup.object().shape({
                    name: Yup.string()
                        .required('Name is Required'),
                    phone: Yup.string()
                        .required("Phone is required"),
                    email: Yup.string()
                        .required("Email is required"),
                    userName: Yup.string()
                        .required("User Name is required")
                })) //end array
        })
    }//end validation schema
    >{({setFieldValue, values}) =>
        <Form>
            <FormErrors errors={errors}>
                <FieldArray
                    name="contacts"
                    render={arrayHelpers => (<>
                            {values.contacts.map((contact, index) => <div className={'p-4 bg-subtle-blue mb-4'}
                                                                          key={index}>
                                <p className={'font-bold'}>Administrator {index + 1}</p>
                                <input type={'hidden'} value={contact.id}/>
                                <div className={"flex"}>
                                    <LabeledField
                                        id={'name'}
                                        className={"flex-1 mr-8"}
                                        label={'Name'}
                                        required
                                        disabled={!enabled[index]}
                                        name={`contacts.${index}.name`}
                                        value={values.contacts[index].name}
                                        onChange={e => {
                                            setFieldValue(`contacts.${index}.name`, e.target.value);
                                        }}
                                        register={`contacts.${index}.name`}
                                    />
                                    <LabeledField
                                        className={"flex-1"}
                                        label={'Title'}
                                        disabled={!enabled[index]}
                                        name={`contacts.${index}.title`}
                                        value={values.contacts[index].title}
                                        onChange={e => {
                                            setFieldValue(`contacts.${index}.title`, e.target.value);
                                        }}
                                        register={`contacts.${index}.title`}
                                    />
                                </div>
                                <div className={"flex"}>
                                    <MaskedLabeledField
                                        value={values.contacts[index].phone}
                                        mask={phoneNumberMask}
                                        label={'Phone'}
                                        required
                                        disabled={!enabled[index]}
                                        name={`contacts.${index}.phone`}
                                        className={"flex-1 mr-8"}
                                        onChange={e => {
                                            setFieldValue(`contacts.${index}.phone`, e.target.value);
                                        }}
                                        register={`contacts.${index}.phone`}
                                        >
                                    </MaskedLabeledField>
                                        <LabeledField
                                        label={'Email '}
                                        className={"flex-1"}
                                        disabled={!enabled[index]}
                                        required
                                        name={`contacts.${index}.email`}
                                        value={values.contacts[index].email}
                                        onChange={e => {
                                            setFieldValue(`contacts.${index}.email`, e.target.value);
                                        }}
                                        register={`contacts.${index}.email`}
                                    />
                                </div>
                                <div className={"flex"}>
                                    <LabeledField
                                        label={'User Name'}
                                        className={"flex-1 mr-8"}
                                        disabled={!enabled[index]}
                                        required
                                        name={`contacts.${index}.userName`}
                                        value={values.contacts[index].userName}
                                        onBlur={e =>{
                                            verifyContactUserName(e.target.value);
                                            setUserName(e.target.value);
                                        }}
                                        onChange={e => {
                                            setFieldValue(`contacts.${index}.userName`, e.target.value);
                                        }}
                                        register={`contacts.${index}.userName`}
                                    />
                                    <div className={"flex-1 pt-11 pb-3 flex"}>
                                        <PrimaryButton type={'button'} className={'pr-4 mr-2'}
                                                       onClick={() => setEnabled({[index] : true, ...enabled})}>Edit</PrimaryButton>
                                        <PrimaryButton type={'button'} className={'mr-2'}
                                                       onClick={() => {onRemove(contact.id); arrayHelpers.remove(index) }}>Remove</PrimaryButton>

                                        {submitting ? <Loading size={30}/> : <PrimaryButton>Save</PrimaryButton>}
                                    </div>                                    </div>
                            </div>)}
                            <FieldGroup>
                                <PrimaryButton type={'button'} onClick={() => {
                                    arrayHelpers.push({
                                        id: '',
                                        name: '',
                                        phone: '',
                                        email: '',
                                        title: '',
                                        companyId: '',
                                        userName: '',
                                    });
                                    setEnabled({[arrayHelpers.form.values? arrayHelpers.form.values.contacts.length: 0]: true, ...enabled});
                                }}>Add Administrator</PrimaryButton>
                            </FieldGroup>
                        </>
                    )}
                />
            </FormErrors>
        </Form>}
    </Formik>
    </>;
};

export default CompanyContactForm;
